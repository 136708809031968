$bgColor: #fff;
$text: #575757;
$dividerBgColor: #C7CAC3;
$outerCircleBgColor: #C7CAC2;
$innerCircleBgColor: #819496;
$full: 100%;
$center: 0 auto;
$inlineBlock: inline-block;

@font-face {
    font-family: 'OpenSans Light';
    src: url('assets/fonts/opensans-light-webfont.eot');
    src: url('assets/fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/opensans-light-webfont.woff2') format('woff2'),
         url('assets/fonts/opensans-light-webfont.woff') format('woff'),
         url('assets/fonts/opensans-light-webfont.ttf') format('truetype'),
         url('assets/fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans Semibold';
    src: url('assets/fonts/opensans-semibold-webfont.eot');
    src: url('assets/fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('assets/fonts/opensans-semibold-webfont.woff') format('woff'),
         url('assets/fonts/opensans-semibold-webfont.ttf') format('truetype'),
         url('assets/fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Vidaloka Regular';
    src: url('assets/fonts/vidaloka-regular-webfont.eot');
    src: url('assets/fonts/vidaloka-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/vidaloka-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/vidaloka-regular-webfont.woff') format('woff'),
         url('assets/fonts/vidaloka-regular-webfont.ttf') format('truetype'),
         url('assets/fonts/vidaloka-regular-webfont.svg#vidaloka_regular') format('svg');
    font-weight: normal;
    font-style: normal;

}

h1 {
	font-family: "Vidaloka Regular";
	font-size: 40px;
	margin: 0;
}

h2 {
	font-family: "OpenSans Semibold";
	font-size: 24px;
	margin-top: 0;

	span {
		font-weight: normal;
	}
}

a, 
a:hover,
a:visited {
	color: $text;
	text-decoration: none;
}

strong {
	font-family: "OpenSans Semibold"
}

html {
	height: $full;

	body {
		font-family: "OpenSans Light";
		font-size: 18px;
		margin: $center;
		height: $full;
		color: $text;		

		#root {
			max-width: 1540px;
		}

		#bg {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index:-1;
			background-image: url('https://wp.paulabramwell.com.au/wp-content/uploads/2018/10/maple-leaves.jpg');
			background-color: $bgColor;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}

		#base {
			background-color: rgba(255, 255, 255, 0.9);
			margin: $center;
			width: 80%;
			border-radius: 12px;

			header {
				margin-top: 19px;
				width: $full;
				height: 270px;
				border-bottom: solid 11px $dividerBgColor;
				
				.wrapper {
					padding-top: 15px;

					#logo {
						width: 392px;
						height: 228px;
						background-image: url('https://wp.paulabramwell.com.au/wp-content/uploads/2018/10/paula-bramwell-enlighten-empower-evolve.png');
						background-repeat: no-repeat;
						background-size: contain;
						display: $inlineBlock;
						margin-left: calc(50% - 319px);
						opacity: 1;
						transition: 300ms;

						&.fade {
							opacity: 1;
						}
					}

					ul {
						padding: 0 0 0 37px;
						margin: 0;
						display: $inlineBlock;
						vertical-align: super;

						li {
							margin: 0;
							padding: 0;
							display: $inlineBlock;
						}
					}
				}
			}
			
			.main-nav {

				ul {
					justify-content: center;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					list-style: none;
					margin: 0 auto;
    				width: 90%;
				}

				li {

					&:first-child {
						margin-left: 18px;
					}
				}

				a {
					padding: 1.25rem 0.5rem;
					font-size: 1.5rem;
					font-weight: bold;
					text-decoration: none;
					display: flex;
					align-items: flex-start;
					
					&:hover, &:focus, &.active {
						background: rgba(199,202,195, 0.5)
					}
				}

				span {
					display: block;
					font-size: 1rem;
					font-weight: normal;
					color: #888;
					margin: 0.25rem 0 0 0;
				}

				&.outlines {
					* {
						outline: 1px solid rgba(red, 0.5); 
					}
				}
			}

			section {
				border-bottom: solid 31px $dividerBgColor;
				padding: 49px 0;
				
				.wrapper {
					margin: $center;
					width: 90%;

					#portrait {
						display: $inlineBlock;
						width: 348px;
						height: 345px;
						background-size: contain;
						background-repeat: no-repeat;
						opacity: 0;
						transition: 300ms;
						&.fade {
							opacity: 1;
						}
					}

					#content {
						//background-color: rgba(255, 255, 255, 0.7);
						display: $inlineBlock;
						margin-left: 23px;
						vertical-align: top;

						ul {
						
							li {
								padding: 3px 0;
							}
						}

						img {
							max-width: 100%;
						} 

						.inline-img-left {
							img {
								float: left;
								padding-right: 11px;
							}
							
						}

						.inline-img-right {
							img {
								float: right;
								padding-right: 11px;
							}
						}

						.posts-item-image {
							float: left;
							padding-right: 11px;
							width: 25%;
						}
						
						.cta {
							float: left;
							width: 32%;
							margin-left: 10px;


							img {
								width: 100% !important;
							}
						}

						.has-small-font-size {
							font-size: 12px;
						}

					}
				}
			}

			footer {
				width: $full;
				border-top: solid 1px $dividerBgColor;
				margin: 7px 0 15px 0;
				padding-bottom: 15px;

				ul {
					margin: 17px 0 0 0;
					padding: 0;
					text-align: center;

					li {
						padding: 0 0 0 2px;
						margin: 3px 0;
						display: inline-block;
					}
				}
			}
		}	
	}
}

@media only screen and (max-width: 1024px) {
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 18px;
	}

	html {
		
		body {

			#base {

				header {
					height: 205px;
					padding-bottom: 17px;

					.wrapper {
					
						#logo {
							width: 287px;
							height: 172px;
							margin-left: calc(50% - 160px);
						}

						ul {
							display: block;
							text-align: center;
						}
					}
			}

			section {
				padding: 37px 0 0 0;;
				
				.wrapper {
		
					#content {
						margin-left: 0;

						.cta {
							margin-left: 0;
						}
					}
				}
			}
			}
		}
	}
} 

.readon {
	font-weight: bold;
}

.post {
	margin-bottom: 20px;
	padding-bottom: 10px;
	clear: both;
	overflow: hidden;
	position: relative;
	
	&:not(:last-child) {
		border-bottom: dotted 1px #C7CAC3;
	}

	h2 {
		margin-bottom: 10px;
	}

	img {
		width: 100%
	}
}

@media only screen and (max-width: 768px) {
	html {
		
		body {
			font-size: 83%;	

			#base {

				header {
				
					.wrapper {
					
						#logo {
							margin-left: calc(50% - 163px);
						}
					}
				}

				.main-nav {

					li {
						width: 100%;

						&:first-child {
							margin-left: 0;
						}

						a {
							justify-content: center;
							font-size: 1.25rem;
							padding: 0.6rem 0rem;
							max-width: 100%;
						}
					}
				}

				section {
					padding: 17px 0 0 0;
					
					.wrapper {
					
						#content {
							width: 100%;
							margin-left: 0;

							.cta {
								width: 100%;
							}
						}
					}
				}	
			}
		}
	}
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 160px;
	top: 50px;

	@media only screen and (max-width: 1024px) {
		left: 125px;
	}

	@media only screen and (max-width: 768px) {
		left: 100px;
	}

	@media only screen and (max-width: 615px) {
		left: 360px;
	}

	@media only screen and (max-width: 470px) {
		left: 320px;
	}

	@media only screen and (max-width: 375px) {
		left: 280px;
	}
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: rgba(87, 87, 87, 0.5);
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #575757;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
	height: 24px;
	width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
	background: #575757;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	position: fixed;
	top: 0;
	height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
	background: rgba(255, 255, 255, 0.9);
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #575757;
	padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
	display: inline-block;
	font-family: "OpenSans Semibold";
	padding-bottom: 0.5em;

		& div {
			font-family: "OpenSans Light";
			font-size: 0.9em;
			padding-left: 0.2em;
			padding-top: 0.2em;
			padding-bottom: 0.2em;
		}
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
	top: 0;
  }
